import { Route, Routes } from "react-router-dom";
import SubscriptionList from "../../components/subscription-list/subscription-list.component";
import CreateSubscriptionForm from "../../components/create-subscription-form/create-subscription-form.component";
import SubscriptionDetailsPage from "../../components/subscription-details-page/subscription-details-page.component";
import EditSellingPlan from "../../components/edit-selling-plan/edit-selling-plan.component";

const Subscription = () => {
  return (
    <Routes>
      <Route index element={<SubscriptionList />} />
      <Route path="create" element={<CreateSubscriptionForm />} />
      <Route
        path="details/:subscriptionId"
        element={<SubscriptionDetailsPage />}
      />
      <Route
        path="sellingplandetails/:sellingPlanId"
        element={<EditSellingPlan />}
      />
    </Routes>
  );
};

export default Subscription;
