import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { netural400, netural50 } from "../../constants/color";
import { neturalBlack } from "../../constants/color";
import CustomLabel from "../shared/label/custom-label.component";
import { Form } from "react-bootstrap";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useState } from "react";

const ModalPopup = (props) => {
  const { buttonType, title, body, body2, submitButton, okayButtonText } =
    props;
  const [cancelReason, setCancelReason] = useState("");
  const [isValidCancelReason, setIsValidCancelReason] = useState(false);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: 0, marginLeft: "3%", marginRight: "3%" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <CustomLabel
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: neturalBlack,
            }}
          >
            {title}
          </CustomLabel>
        </Modal.Title>
      </Modal.Header>
      {buttonType === "Cancel subscription" && (
        <div
          style={{
            borderBottom: "1px solid #dadada",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        ></div>
      )}
      <Modal.Body style={{ marginLeft: "3%", marginRight: "3%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CustomLabel
            style={{
              fontSize: "16px",
              color: netural400,
            }}
          >
            {body}
          </CustomLabel>
          <CustomLabel
            style={{
              fontSize: "16px",
              color: netural400,
            }}
          >
            {body2}
          </CustomLabel>
          {buttonType === "Cancel subscription" && (
            <div style={{ marginTop: "4%" }}>
              <Form.Group controlId="validationCustom03">
                <Form.Label style={{ fontSize: "16px", color: netural400 }}>
                  Reason for deleting subscription*
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={5}
                  value={cancelReason}
                  onChange={(value) => {
                    if (value.target.value.trim().length !== 0) {
                      setCancelReason(value.target.value);
                    } else {
                      setCancelReason("");
                    }
                  }}
                  minLength={50}
                  maxLength={3000}
                />
                {isValidCancelReason && (
                  <CustomLabel
                    style={{
                      fontSize: "12px",
                      color: "red",
                      width: "100%",
                      marginTop: "2%",
                    }}
                  >
                    <IoAlertCircleOutline
                      size={16}
                      color={"red"}
                      style={{ marginRight: "2%" }}
                    />
                    Please provide valid cancelation reason!
                  </CustomLabel>
                )}
              </Form.Group>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ borderTop: 0, marginLeft: "3%", marginRight: "3%" }}
      >
        {props.onCancelPress ? (
          <Button
            style={{
              backgroundColor: netural50,
              borderColor: netural50,
              color: neturalBlack,
              borderRadius: "12px",
              paddingLeft: "2%",
              paddingRight: "2%",
              fontWeight: "bold",
            }}
            onClick={props.onCancelPress}
          >
            Cancel
          </Button>
        ) : null}
        {props.onSubmit ? (
          <Button
            style={{
              color: "white",
              backgroundColor:
                title === "Delete Service" ||
                "Delete Product" ||
                "Cancel subscription?"
                  ? "#ea1010"
                  : "green",
              borderRadius: "12px",
              paddingLeft: "2%",
              paddingRight: "2%",
              fontWeight: "bold",
              borderColor: "#ea1010",
            }}
            onClick={() => {
              if (buttonType === "Cancel subscription") {
                if (cancelReason === "") {
                  setIsValidCancelReason(true);
                } else {
                  props.onSubmit(cancelReason);
                }
              } else {
                props.onSubmit();
              }
            }}
          >
            {submitButton}
          </Button>
        ) : null}
        {props.onOkayPress ? (
          <Button
            style={{
              backgroundColor: netural50,
              borderColor: netural50,
              color: neturalBlack,
              borderRadius: "12px",
              paddingLeft: "2%",
              paddingRight: "2%",
              fontWeight: "bold",
            }}
            onClick={props.onOkayPress}
          >
            {okayButtonText}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPopup;
