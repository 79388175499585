import { Col, Image, Row } from "react-bootstrap";
import SectionTitle from "../section-title/section-title.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400, neturalBlack, primaryOrange } from "../../constants/color";
import { useEffect, useState } from "react";
import SelectProductModel from "../select-product-model/select-product-model.component";
import { IoAddOutline, IoTrashSharp } from "react-icons/io5";
import SubscriptionProductListDesign from "../subscription-product-list-design/subscription-product-list-design.component";
import EmptySubscriptionSellingPlan from "../empty-subscription-selling-plan/empty-subscription-selling-plan.component";
import { useMutation } from "@apollo/client";
import { CREATE_PRODUCT_SELLING_PLAN } from "../../services/graphql/autoship/createAutoship";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";

const SubscriptionSelectProductPage = ({
  handleBackClick,
  setSelectedProductsValue,
  formValue,
}) => {
  const [showAddProductModel, setShowAddProductModel] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isValid, setIsValid] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (formValue.selectedProducts && formValue.selectedProducts?.length > 0) {
      setSelectedProducts(formValue.selectedProducts);
    }
  }, []);

  const [createProductsSellingPlan, { loading: loadingProductSellingPlan }] =
    useMutation(CREATE_PRODUCT_SELLING_PLAN, {
      onCompleted: (data) => {
        toast.success("Plan created successfully", {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
        navigate("/subscription");
      },
      onError: (error) => {
        console.log(error, "ERROR==============");
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  const handleSubmitButton = async () => {
    if (selectedProducts.length === 0) {
      setIsValid(true);
    } else {
      const sellingPlanData = {
        planName: formValue.sellingPlanName,
        discount: parseFloat(formValue.productDiscount),
        frequencyType: formValue.sellingPlanFrequency.toLowerCase(),
        minimumCycle: parseInt(formValue.minimumBillingCycle),
        products: selectedProducts.map((product) => {
          return {
            id: product._id,
          };
        }),
      };
      await createProductsSellingPlan({
        variables: {
          createSellingPlanInput: sellingPlanData,
        },
      });
    }
  };

  const handleDelete = (allProduct) => {
    setSelectedProducts((prevSelectedProduct) =>
      prevSelectedProduct.filter((selected) => selected._id !== allProduct._id)
    );
  };

  return (
    <div>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle
          title={"Products and variants"}
          subtitle={
            "Click below to add products and variants to this selling plan"
          }
        />
      </div>
      <Row>
        <Col xl={5} lg={5} md={5}>
          <div
            style={{
              border: "solid 1px #dadada",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "solid 1px #dadada",
                paddingBottom: "2%",
              }}
            >
              <CustomLabel
                style={{
                  paddingLeft: "2%",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: netural400,
                }}
              >
                Showing {selectedProducts.length} products
              </CustomLabel>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: "2%",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowAddProductModel(true);
                }}
              >
                <IoAddOutline size={16} color={primaryOrange} />
                <CustomLabel
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: primaryOrange,
                  }}
                >
                  Add product
                </CustomLabel>
              </div>
            </div>
            <div style={{ marginTop: "2%" }}>
              {selectedProducts.length === 0 ? (
                <EmptySubscriptionSellingPlan
                  mainTitle="No product selected"
                  subTitle="Please select products which you want to include inside
                    autoship."
                  showButton={false}
                  imageSize="small"
                />
              ) : (
                selectedProducts.map((allProduct, index) => {
                  return (
                    <SubscriptionProductListDesign
                      allProduct={allProduct}
                      products={selectedProducts}
                      index={index}
                      showCheckBox={false}
                      showPrice={false}
                      showDelete={true}
                      showEdit={false}
                      imageColValue={10}
                      handleDelete={handleDelete}
                    />
                  );
                })
              )}
            </div>
          </div>
          {isValid && (
            <div>
              <CustomLabel style={{ fontSize: "14px", color: "red" }}>
                Please select any one product to create selling plan.
              </CustomLabel>
            </div>
          )}
          {loadingProductSellingPlan ? (
            <LoadingSpinner />
          ) : (
            <FormFooterButtons
              displayPreviousButton={true}
              previousButtonText={"Back"}
              handlePreviouButtonClick={() => {
                setSelectedProductsValue(selectedProducts);
                handleBackClick();
              }}
              nextButtonText={"Save selling plan"}
              handleNextButtonClick={handleSubmitButton}
            />
          )}
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image
            src="../images/subscription-image.png"
            alt="subscription-image"
            style={{ width: "90%" }}
          />
        </Col>
      </Row>
      {showAddProductModel && (
        <SelectProductModel
          show={showAddProductModel}
          onHide={() => {
            setShowAddProductModel(false);
          }}
          handleAddProduct={(selectedProduct) => {
            setSelectedProducts(selectedProduct);
            setShowAddProductModel(false);
            setIsValid(false);
          }}
          selectedProducts={selectedProducts}
        />
      )}
    </div>
  );
};

export default SubscriptionSelectProductPage;
