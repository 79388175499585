import { Fragment } from "react";
import { netural400, neturalBlack } from "../../constants/color";

const PaymentDetail = ({ paymentDetail, type }) => {
  return (
    <div
      style={{
        marginTop: "5%",
        paddingBottom: "4%",
      }}
    >
      <Fragment>
        <label
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: neturalBlack,
          }}
        >
          Payment summary
        </label>
      </Fragment>
      <div
        style={{
          marginTop: "3%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Item(s) Price:
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          $ {paymentDetail.itemPrice}
        </label>
      </div>
      {paymentDetail.coupon && (
        <div
          style={{
            marginTop: "1%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Align items vertically center
          }}
        >
          {/* Left Side: Coupon Discount and Code */}
          <div style={{ flex: 1 }}>
            <label
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: neturalBlack,
                display: "block", // Ensure block layout for stacking text
              }}
            >
              Coupon Discount{" "}
              {paymentDetail.coupon.type === "percentage"
                ? `(${paymentDetail.coupon.discountPercentage}%):`
                : `($${paymentDetail.coupon.discountedAmount}):`}
            </label>
            <label
              style={{
                color: "#7d7d7d", // Lighter color for coupon code
                marginTop: "2px", // Slight margin for separation
                display: "block",
              }}
            >
              Code: {paymentDetail.coupon.code}
            </label>
          </div>

          {/* Right Side: Discounted Amount */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ fontSize: "16px", color: netural400 }}>
              - $ {paymentDetail.coupon.discountedAmount}
            </label>
          </div>
        </div>
      )}
      {paymentDetail.sellingPlan && (
        <div
          style={{
            marginTop: "1%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Autoship Discount ({paymentDetail.sellingPlan.discount}%)
          </label>
          <label style={{ fontSize: "16px", color: netural400 }}>
            - $ {paymentDetail.sellingPlan.sellingPlanDiscount}
          </label>
        </div>
      )}
      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Subscription Points:
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          - $ {paymentDetail.subscriptionPoints}
        </label>
      </div>

      {type === "Product" ? (
        <div
          style={{
            marginTop: "1%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Shipping:
          </label>
          <label style={{ fontSize: "16px", color: netural400 }}>
            {" "}
            $ {paymentDetail.shippingCost}
          </label>
        </div>
      ) : null}

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Platform Fees ({parseInt(paymentDetail.platformFeesRate)}%)
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          - $ {paymentDetail.platformFees}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Handling Fees
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          $ {paymentDetail.shippingCost}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Tax {paymentDetail.taxRate}%
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          $ {paymentDetail.taxAmount}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Transaction Fees:
          <br></br>
          <label style={{ color: "#7d7d7d" }}>{paymentDetail.stripeTransactionFees}</label>
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          - $ {paymentDetail.transactionFees}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Total
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          $ {paymentDetail.totalAmount}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Net Income
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          $ {paymentDetail.netIncome}
        </label>
      </div>
    </div>
  );
};

export default PaymentDetail;
