import { netural400, neturalBlack } from "../../constants/color";

const SectionTitle = ({ title, subtitle, titleStyle }) => {
  const defaultTitleStyle = {
    marginTop: "3%",
    marginBottom: "3%",
    display: "flex",
    flexDirection: "column",
  };
  const mergedTitleStyle = {
    ...defaultTitleStyle,
    ...titleStyle,
  };
  return (
    <div style={mergedTitleStyle}>
      <label
        style={{
          fontWeight: "700",
          fontSize: "22px",
          color: neturalBlack,
        }}
      >
        {title}
      </label>
      {subtitle ? (
        <label
          style={{
            fontSize: "16px",
            color: netural400,
          }}
        >
          {subtitle}
        </label>
      ) : null}
    </div>
  );
};
export default SectionTitle;
