import { React, useRef, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { primaryOrange } from "../../constants/color";
import { useEffect } from "react";
import moment from "moment";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { IconContext } from "react-icons";
import { IoInformationCircleOutline } from "react-icons/io5";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";

const ServiceScheduleForm = ({
  handleBackClick,
  handleNextClick,
  formValue,
}) => {
  const [validated, setValidated] = useState(false);
  const [isSelectedSunday, setIsSelectedSunday] = useState(true);
  const [isSelectedMonday, setIsSelectedMonday] = useState(true);
  const [isSelectedTuesday, setIsSelectedTuesday] = useState(true);
  const [isSelectedWednesday, setIsSelectedWednesday] = useState(true);
  const [isSelectedThursday, setIsSelectedThursday] = useState(true);
  const [isSelectedFriday, setIsSelectedFriday] = useState(true);
  const [isSelectedSaturday, setIsSelectedSaturday] = useState(true);

  // Service Duration time
  const [serviceTimeHour, setServiceTimeHour] = useState("");
  const [serviceTimeMin, setServiceTimeMin] = useState("");

  // Break time
  // const [breakTimeFrom, setBreakTimeFrom] = useState("");
  const [breakTimeTo, setBreakTimeTo] = useState("");

  // Sunday
  const [operatingSundayFrom, setOperatingSundayFrom] = useState("");
  const [operatingSundayTo, setOperatingSundayTo] = useState("");
  const [slotsBeforeBreakSunday, setSlotsBeforeBreakSunday] = useState([]);
  const [slotsAfterBreakSunday, setSlotsAfterBreakSunday] = useState([]);
  const [lastTimeSlotBeforeBreakSunday, setLastTimeSoltBeforeBreakSunday] =
    useState("");
  const [lastTimeSlotAfterBreakSunday, setLastTimeSoltAfterBreakSunday] =
    useState("");

  // Monday
  const [operatingMondayFrom, setOperatingMondayFrom] = useState("");
  const [operatingMondayTo, setOperatingMondayTo] = useState("");
  const [slotsBeforeBreakMonday, setSlotsBeforeBreakMonday] = useState([]);
  const [slotsAfterBreakMonday, setSlotsAfterBreakMonday] = useState([]);
  const [lastTimeSlotBeforeBreakMonday, setLastTimeSlotBeforeBreakMonday] =
    useState("");
  const [lastTimeSlotAfterBreakMonday, setLastTimeSlotAfterBreakMonday] =
    useState("");

  // Tuesday
  const [operatingTuesdayFrom, setOperatingTuesdayFrom] = useState("");
  const [operatingTuesdayTo, setOperatingTuesdayTo] = useState("");
  const [slotsBeforeBreakTuesday, setSlotsBeforeBreakTuesday] = useState([]);
  const [slotsAfterBreakTuesday, setSlotsAfterBreakTuesday] = useState([]);
  const [lastTimeSlotBeforeBreakTuesday, setLastTimeSoltBeforeBreakTuesday] =
    useState("");
  const [lastTimeSlotAfterBreakTuesday, setLastTimeSoltAfterBreakTuesday] =
    useState("");

  // Wednesday
  const [operatingWednesdayFrom, setOperatingWednesdayFrom] = useState("");
  const [operatingWednesdayTo, setOperatingWednesdayTo] = useState("");
  const [slotsBeforeBreakWednesday, setSlotsBeforeBreakWednesday] = useState(
    []
  );
  const [slotsAfterBreakWednesday, setSlotsAfterBreakWednesday] = useState([]);
  const [
    lastTimeSlotBeforeBreakWednesday,
    setLastTimeSoltBeforeBreakWednesday,
  ] = useState([]);
  const [lastTimeSlotAfterBreakWednesday, setLastTimeSoltAfterBreakWednesday] =
    useState([]);

  // Thursday
  const [operatingThursdayFrom, setOperatingThursdayFrom] = useState("");
  const [operatingThursdayTo, setOperatingThursdayTo] = useState("");
  const [slotsBeforeBreakThursday, setSlotsBeforeBreakThursday] = useState([]);
  const [slotsAfterBreakThursday, setSlotsAfterBreakThursday] = useState([]);
  const [lastTimeSlotBeforeBreakThursday, setLastTimeSoltBeforeBreakThursday] =
    useState([]);
  const [lastTimeSlotAfterBreakThursday, setLastTimeSoltAfterBreakThursday] =
    useState([]);

  // Friday
  const [operatingFridayFrom, setOperatingFridayFrom] = useState("");
  const [operatingFridayTo, setOperatingFridayTo] = useState("");
  const [slotsBeforeBreakFriday, setSlotsBeforeBreakFriday] = useState([]);
  const [slotsAfterBreakFriday, setSlotsAfterBreakFriday] = useState([]);
  const [lastTimeSlotBeforeBreakFriday, setLastTimeSoltBeforeBreakFriday] =
    useState([]);
  const [lastTimeSlotAfterBreakFriday, setLastTimeSoltAfterBreakFriday] =
    useState([]);

  // Saturday
  const [operatingSaturdayFrom, setOperatingSaturdayFrom] = useState("");
  const [operatingSaturdayTo, setOperatingSaturdayTo] = useState("");
  const [slotsBeforeBreakSaturday, setSlotsBeforeBreakSaturday] = useState([]);
  const [slotsAfterBreakSaturday, setSlotsAfterBreakSaturday] = useState([]);
  const [lastTimeSlotBeforeBreakSaturday, setLastTimeSoltBeforeBreakSaturday] =
    useState([]);
  const [lastTimeSlotAfterBreakSaturday, setLastTimeSoltAfterBreakSaturday] =
    useState([]);

  const [isLoadedData, setIsLoadedData] = useState(true);

  const topScrollbarRef = useRef(null);
  const bottomScrollbarRef = useRef(null);

  // Function to sync the scrolling of both divs
  const syncScroll = (e) => {
    if (e.target === topScrollbarRef.current) {
      bottomScrollbarRef.current.scrollLeft = e.target.scrollLeft;
    } else {
      topScrollbarRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  useEffect(() => {
    if (formValue.serviceTimeHour && formValue.serviceTimeHour !== "") {
      // console.log("Slots--------------", formValue);
      setServiceTimeHour(formValue.serviceTimeHour);
      setServiceTimeMin(formValue.serviceTimeMin);

      // setBreakTimeFrom(formValue.serviceBreakFrom);
      setBreakTimeTo(formValue.serviceBreakTo);

      setIsSelectedSunday(formValue.serviceOperatingSunday);
      setIsSelectedMonday(formValue.serviceOperatingMonday);
      setIsSelectedTuesday(formValue.serviceOperatingTuesday);
      setIsSelectedWednesday(formValue.serviceOperatingWednesday);
      setIsSelectedThursday(formValue.serviceOperatingThursday);
      setIsSelectedFriday(formValue.serviceOperatingFriday);
      setIsSelectedSaturday(formValue.serviceOperatingSaturday);

      setOperatingSundayFrom(formValue.serviceOperatingSundayFrom);
      setOperatingSundayTo(formValue.serviceOperatingSundayTo);
      setSlotsBeforeBreakSunday(formValue.slotsBeforeBreakSunday);
      setSlotsAfterBreakSunday(formValue.slotsAfterBreakSunday);

      setOperatingMondayFrom(formValue.serviceOperatingMondayFrom);
      setOperatingMondayTo(formValue.serviceOperatingMondayTo);
      setSlotsBeforeBreakMonday(formValue.slotsBeforeBreakMonday);
      setSlotsAfterBreakMonday(formValue.slotsAfterBreakMonday);

      setOperatingTuesdayFrom(formValue.serviceOperatingTuesdayFrom);
      setOperatingTuesdayTo(formValue.serviceOperatingTuesdayTo);
      setSlotsBeforeBreakTuesday(formValue.slotsBeforeBreakTuesday);
      setSlotsAfterBreakTuesday(formValue.slotsAfterBreakTuesday);

      setOperatingWednesdayFrom(formValue.serviceOperatingWednesdayFrom);
      setOperatingWednesdayTo(formValue.serviceOperatingWednesdayTo);
      setSlotsBeforeBreakWednesday(formValue.slotsBeforeBreakWednesday);
      setSlotsAfterBreakWednesday(formValue.slotsAfterBreakWednesday);

      setOperatingThursdayFrom(formValue.serviceOperatingThursdayFrom);
      setOperatingThursdayTo(formValue.serviceOperatingThursdayTo);
      setSlotsBeforeBreakThursday(formValue.slotsBeforeBreakThursday);
      setSlotsAfterBreakThursday(formValue.slotsAfterBreakThursday);

      setOperatingFridayFrom(formValue.serviceOperatingFridayFrom);
      setOperatingFridayTo(formValue.serviceOperatingFridayTo);
      setSlotsBeforeBreakFriday(formValue.slotsBeforeBreakFriday);
      setSlotsAfterBreakFriday(formValue.slotsAfterBreakFriday);

      setOperatingSaturdayFrom(formValue.serviceOperatingSaturdayFrom);
      setOperatingSaturdayTo(formValue.serviceOperatingSaturdayTo);
      setSlotsBeforeBreakSaturday(formValue.slotsBeforeBreakSaturday);
      setSlotsAfterBreakSaturday(formValue.slotsAfterBreakSaturday);
    }
    calculateSlots();
  }, []);

  const calculateSlots = () => {
    let converLastSlotTimeBeforeSunday = new Date();
    converLastSlotTimeBeforeSunday.setHours(
      formValue.serviceOperatingSundayFrom,
      0,
      0
    );
    setLastTimeSoltBeforeBreakSunday(
      moment(converLastSlotTimeBeforeSunday).format("HH:mm")
    );
    let converLastSlotTimeAfterSunday = new Date();
    converLastSlotTimeAfterSunday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSoltAfterBreakSunday(
      moment(converLastSlotTimeAfterSunday).format("HH:mm")
    );

    let converLastSlotTimeBeforeMonday = new Date();
    converLastSlotTimeBeforeMonday.setHours(
      formValue.serviceOperatingMondayFrom,
      0,
      0
    );
    setLastTimeSlotBeforeBreakMonday(
      moment(converLastSlotTimeBeforeMonday).format("HH:mm")
    );
    let converLastSlotTimeAfterMonday = new Date();
    converLastSlotTimeAfterMonday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSlotAfterBreakMonday(
      moment(converLastSlotTimeAfterMonday).format("HH:mm")
    );

    let converLastSlotTimeBeforeTuesday = new Date();
    converLastSlotTimeBeforeTuesday.setHours(
      formValue.serviceOperatingTuesdayFrom,
      0,
      0
    );
    setLastTimeSoltBeforeBreakTuesday(
      moment(converLastSlotTimeBeforeTuesday).format("HH:mm")
    );

    let converLastSlotTimeAfterTuesday = new Date();
    converLastSlotTimeAfterTuesday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSoltAfterBreakTuesday(
      moment(converLastSlotTimeAfterTuesday).format("HH:mm")
    );

    let converLastSlotTimeBeforeWednesday = new Date();
    converLastSlotTimeBeforeWednesday.setHours(
      formValue.serviceOperatingWednesdayFrom,
      0,
      0
    );
    setLastTimeSoltBeforeBreakWednesday(
      moment(converLastSlotTimeBeforeWednesday).format("HH:mm")
    );

    let converLastSlotTimeAfterWednesday = new Date();
    converLastSlotTimeAfterWednesday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSoltAfterBreakWednesday(
      moment(converLastSlotTimeAfterWednesday).format("HH:mm")
    );

    let converLastSlotTimeBeforeThursday = new Date();
    converLastSlotTimeBeforeThursday.setHours(
      formValue.serviceOperatingThursdayFrom,
      0,
      0
    );
    setLastTimeSoltBeforeBreakThursday(
      moment(converLastSlotTimeBeforeThursday).format("HH:mm")
    );

    let converLastSlotTimeAfterThursday = new Date();
    converLastSlotTimeAfterThursday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSoltAfterBreakThursday(
      moment(converLastSlotTimeAfterThursday).format("HH:mm")
    );

    let converLastSlotTimeBeforeFriday = new Date();
    converLastSlotTimeBeforeFriday.setHours(
      formValue.serviceOperatingFridayFrom,
      0,
      0
    );
    setLastTimeSoltBeforeBreakFriday(
      moment(converLastSlotTimeBeforeFriday).format("HH:mm")
    );

    let converLastSlotTimeAfterFriday = new Date();
    converLastSlotTimeAfterFriday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSoltAfterBreakFriday(
      moment(converLastSlotTimeAfterFriday).format("HH:mm")
    );

    let converLastSlotTimeBeforeSaturday = new Date();
    converLastSlotTimeBeforeSaturday.setHours(
      formValue.serviceOperatingSaturdayFrom,
      0,
      0
    );
    setLastTimeSoltBeforeBreakSaturday(
      moment(converLastSlotTimeBeforeSaturday).format("HH:mm")
    );

    let converLastSlotTimeAfterSaturday = new Date();
    converLastSlotTimeAfterSaturday.setHours(formValue.serviceBreakTo, 0, 0);
    setLastTimeSoltAfterBreakSaturday(
      moment(converLastSlotTimeAfterSaturday).format("HH:mm")
    );
    setIsLoadedData(false);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  let tempSlotBeforeValueHour = "";
  let tempSlotBeforeValueMin = "";
  let tempSlotAfterValueHour = "";
  let tempSlotAfterValueMin = "";
  let last = "last";

  const calculateTimeSlotsBefore = (
    operatingHourFrom,
    operatingHourTo,
    last
  ) => {
    const startTimeInDate = new Date();
    startTimeInDate.setHours(
      tempSlotBeforeValueHour === ""
        ? operatingHourFrom
        : tempSlotBeforeValueHour,
      tempSlotBeforeValueMin === "" ? 0 : tempSlotBeforeValueMin,
      0
    );

    let slotDuration =
      parseInt(serviceTimeHour) * 60 + parseInt(serviceTimeMin);
    let finalSlot = new Date(startTimeInDate.getTime() + slotDuration * 60000);
    let formatedTimeSlot = moment(finalSlot).format("HH:mm");

    let tempSplitValue = formatedTimeSlot.split(":");
    tempSlotBeforeValueHour = parseInt(tempSplitValue[0]);
    tempSlotBeforeValueMin = parseInt(tempSplitValue[1]);

    // console.log("Coming --------------------------", last);
    if (last === "last") {
      tempSlotBeforeValueHour = "";
      tempSlotBeforeValueMin = "";
    }

    return formatedTimeSlot;
  };

  const calculateTimeSlotsAfter = (last) => {
    const startTimeInDate = new Date();
    startTimeInDate.setHours(
      tempSlotAfterValueHour === "" ? breakTimeTo : tempSlotAfterValueHour,
      tempSlotAfterValueMin === "" ? 0 : tempSlotAfterValueMin,
      0
    );

    let slotDuration =
      parseInt(serviceTimeHour) * 60 + parseInt(serviceTimeMin);
    let finalSlot = new Date(startTimeInDate.getTime() + slotDuration * 60000);
    let formatedTimeSlot = moment(finalSlot).format("HH:mm");

    let tempSplitValue = formatedTimeSlot.split(":");
    tempSlotAfterValueHour = parseInt(tempSplitValue[0]);
    tempSlotAfterValueMin = parseInt(tempSplitValue[1]);
    if (last === "last") {
      tempSlotAfterValueHour = "";
      tempSlotAfterValueMin = "";
    }
    return formatedTimeSlot;
  };

  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "3%",
            backgroundColor: "#FFF3DA",
            padding: 16,
            marginRight: "5%",
          }}
        >
          <IconContext.Provider value={{ size: 22, color: "black" }}>
            <IoInformationCircleOutline />
          </IconContext.Provider>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#545454",
              marginLeft: 10,
            }}
          >
            You won’t be able to edit this information later.
          </label>
        </div>
        <div style={{ marginTop: "5%", marginBottom: "3%" }}>
          <SectionTitle
            title={"Service schedule"}
            subtitle={
              "Control days and hours you’ll be providing your service."
            }
          />
        </div>
      </div>
      {!isLoadedData ? (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {/* Top Scrollbar */}
              <div
                ref={topScrollbarRef}
                onScroll={syncScroll}
                style={{
                  overflowX: "auto",
                  height: "24px",
                  marginBottom: "2%", // add space between the scrollbars
                  width: "95%",
                }}
              >
                <div style={{ width: "130%" }}></div>
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  width: "95%",
                }}
                ref={bottomScrollbarRef}
                onScroll={syncScroll}
              >
                {isSelectedSunday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%", marginBottom: '2%' }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Sun"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakSunday
                      ? slotsBeforeBreakSunday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakSunday
                                      ? lastTimeSlotBeforeBreakSunday
                                      : null
                                    : index ===
                                      slotsBeforeBreakSunday.length - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingSundayFrom,
                                        operatingSundayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingSundayFrom,
                                        operatingSundayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakSunday
                      ? slotsAfterBreakSunday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakSunday
                                      ? lastTimeSlotAfterBreakSunday
                                      : null
                                    : index === slotsAfterBreakSunday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
                {isSelectedMonday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Mon"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakMonday
                      ? slotsBeforeBreakMonday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakMonday
                                      ? lastTimeSlotBeforeBreakMonday
                                      : null
                                    : index ===
                                      slotsBeforeBreakMonday.length - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingMondayFrom,
                                        operatingMondayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingMondayFrom,
                                        operatingMondayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakMonday
                      ? slotsAfterBreakMonday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakMonday
                                      ? lastTimeSlotAfterBreakMonday
                                      : null
                                    : index === slotsAfterBreakMonday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
                {isSelectedTuesday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Tue"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakTuesday
                      ? slotsBeforeBreakTuesday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakTuesday
                                      ? lastTimeSlotBeforeBreakTuesday
                                      : null
                                    : index ===
                                      slotsBeforeBreakTuesday.length - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingTuesdayFrom,
                                        operatingTuesdayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingTuesdayFrom,
                                        operatingTuesdayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakTuesday
                      ? slotsAfterBreakTuesday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakTuesday
                                      ? lastTimeSlotAfterBreakTuesday
                                      : null
                                    : index ===
                                      slotsAfterBreakTuesday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
                {isSelectedWednesday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Wed"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakWednesday
                      ? slotsBeforeBreakWednesday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakWednesday
                                      ? lastTimeSlotBeforeBreakWednesday
                                      : null
                                    : index ===
                                      slotsBeforeBreakWednesday.length - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingWednesdayFrom,
                                        operatingWednesdayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingWednesdayFrom,
                                        operatingWednesdayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakWednesday
                      ? slotsAfterBreakWednesday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakWednesday
                                      ? lastTimeSlotAfterBreakWednesday
                                      : null
                                    : index ===
                                      slotsAfterBreakWednesday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
                {isSelectedThursday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Thu"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakThursday
                      ? slotsBeforeBreakThursday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakThursday
                                      ? lastTimeSlotBeforeBreakThursday
                                      : null
                                    : index ===
                                      slotsBeforeBreakThursday.length - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingThursdayFrom,
                                        operatingThursdayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingThursdayFrom,
                                        operatingThursdayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakThursday
                      ? slotsAfterBreakThursday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakThursday
                                      ? lastTimeSlotAfterBreakThursday
                                      : null
                                    : index ===
                                      slotsAfterBreakThursday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
                {isSelectedFriday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Fri"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakFriday
                      ? slotsBeforeBreakFriday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakFriday
                                      ? lastTimeSlotBeforeBreakFriday
                                      : null
                                    : index ===
                                      slotsBeforeBreakFriday.length - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingFridayFrom,
                                        operatingFridayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingFridayFrom,
                                        operatingFridayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakFriday
                      ? slotsAfterBreakFriday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakFriday
                                      ? lastTimeSlotAfterBreakFriday
                                      : null
                                    : index === slotsAfterBreakFriday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
                {isSelectedSaturday ? (
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    style={{ marginRight: "2%" }}
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Sat"
                      checked
                      color="#E97101"
                      onClick={() => {
                        setIsSelectedMonday(false);
                      }}
                      style={{ opacity: 1 }}
                      disabled
                    />
                    {slotsBeforeBreakSaturday
                      ? slotsBeforeBreakSaturday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotBeforeBreakSaturday
                                      ? lastTimeSlotBeforeBreakSaturday
                                      : null
                                    : index === slotsBeforeBreakSaturday - 1
                                    ? calculateTimeSlotsBefore(
                                        operatingSaturdayFrom,
                                        operatingSaturdayTo,
                                        last
                                      )
                                    : calculateTimeSlotsBefore(
                                        operatingSaturdayFrom,
                                        operatingSaturdayTo,
                                        ""
                                      )}
                                  {}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                    {slotsAfterBreakSaturday
                      ? slotsAfterBreakSaturday.map((data, index) => (
                          <div style={{ display: "flex", marginTop: "15%" }}>
                            <Col lg="4" md="6" sm="6" xs="6">
                              <div>
                                <label>
                                  {" "}
                                  {index === 0
                                    ? lastTimeSlotAfterBreakSaturday
                                      ? lastTimeSlotAfterBreakSaturday
                                      : null
                                    : index ===
                                      slotsAfterBreakSaturday.length - 1
                                    ? calculateTimeSlotsAfter(last)
                                    : calculateTimeSlotsAfter("")}{" "}
                                </label>
                              </div>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                              <div
                                style={{
                                  height: "24px",
                                  backgroundColor: primaryOrange,
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </Col>
                          </div>
                        ))
                      : null}
                  </Col>
                ) : null}
              </div>
            </Col>
            <div style={{ marginLeft: "3%" }}>
              <FormFooterButtons
                mdSize="6"
                lgSize="6"
                className="mt-4 mb-4"
                displayPreviousButton={true}
                previousButtonText={"Back"}
                handlePreviouButtonClick={handleBackClick}
                nextButtonText={"Continue"}
                handleNextButtonClick={handleNextClick}
              />
            </div>
            {/* <Col md="6" lg="6" className="mt-4 mb-4">
              <div style={{ display: "flex" }}>
                <Col md="4" lg="2">
                  <div
                    style={{
                      height: "42px",
                      border: "2px solid #E97101",
                      borderRadius: "12px",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "12px",
                    }}
                    onClick={handleBackClick}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        color: "#E97101",
                        fontWeight: "700",
                      }}
                    >
                      Back
                    </label>
                  </div>
                </Col>
                <Col md="8" lg="10">
                  <div
                    style={{
                      height: "42px",
                      backgroundColor: "#E97101",
                      borderRadius: "12px",
                      marginTop: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "12px",
                    }}
                    onClick={handleNextClick}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        color: "white",
                        fontWeight: "700",
                      }}
                    >
                      Continue
                    </label>
                  </div>
                </Col>
              </div>
            </Col> */}
          </Row>
        </Form>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default ServiceScheduleForm;
