import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { MdClear } from "react-icons/md";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400, primaryOrange } from "../../constants/color";

const SearchBar = (props) => {
  // const [searchWord, setSearchWord] = useState("");
  const { searchWord, setSearchWord, setPageNumber } = props;
  return (
    <div
      style={{
        // borderBottom: "1px solid #dadada",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "3%",
      }}
    >
      <Row
        style={{
          display: "flex",
          paddingBottom: "3%",
          width: "100%",
        }}
      >
        {/* <Col lg={12} md={12} sm={12} xs={12}> */}
        <Col xl={10} lg={10} md={6} sm={8} xs={8} style={{ paddingLeft: "2%" }}>
          <InputGroup
            style={{
              color: "#A6A6A6",
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
              borderLeft: "0px solid #dadada",
              borderTop: "1px solid #dadada",
              borderBottom: "1px solid #dadada",
              borderRight: "1px solid #dadada",
              display: "flex",
              alignItems: "center",
              paddingRight: "2%",
            }}
          >
            <IoSearch color={netural400} size={20} />
            <Form.Control
              required
              type="text"
              placeholder="Search products"
              value={searchWord}
              onChange={(value) => {
                if (value.target.value.trim().length !== 0) {
                  setSearchWord(value.target.value);
                } else {
                  setSearchWord("");
                }
              }}
            />
            {searchWord !== "" ? (
              <MdClear
                onClick={() => {
                  setSearchWord("");
                  setPageNumber(1);
                }}
                style={{ marginLeft: "1%", marginRight: "1%" }}
                size={20}
              />
            ) : null}
          </InputGroup>
        </Col>
        <Col
          xl={2}
          lg={2}
          md={6}
          sm={8}
          xs={8}
          style={{
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          onClick={() => {
            props.handleClear();
          }}
        >
          <CustomLabel
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryOrange,
            }}
          >
            Clear
          </CustomLabel>
        </Col>
        {/* </Col> */}
      </Row>
    </div>
  );
};
export default SearchBar;
