import _ from "lodash";

export const variantList = [
  { id: 1, name: "Color" },
  { id: 2, name: "Size" },
  { id: 3, name: "Material" },
  { id: 4, name: "Flavour" },
];

export const monthlyMinimumCycleOptions = [1, 2, 3, 4, 5, 6];
export const weeklyMinimumCycleOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 20, 24,
];

export const returnPaginationRange = (totalPage, page, siblings, limit) => {
  let totalPageNoInArray = 5 + siblings;
  if (totalPageNoInArray >= totalPage) {
    return _.range(1, totalPage + 1);
  }
  let leftSiblingIndex = Math.max(page - siblings, 1);
  let rightSiblingIndex = Math.min(page + siblings, totalPage);

  let showLeftDots = leftSiblingIndex > 2;
  let showRightDots = rightSiblingIndex < totalPage - 2;

  if (!showLeftDots && showRightDots) {
    let leftItemCount = 3 + 2 * siblings;
    let leftRange = _.range(1, leftItemCount + 1);

    return [...leftRange, "...", totalPage];
  } else if (showLeftDots && !showRightDots) {
    let rightItemCount = 3 + 2 * siblings;
    let rightRange = _.range(totalPage - rightItemCount + 1, totalPage + 1);

    return [1, "...", ...rightRange];
  } else {
    let middleRange = _.range(leftSiblingIndex, rightSiblingIndex + 1);

    return [1, "...", ...middleRange, "...", totalPage];
  }
};
