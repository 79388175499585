import { Col, Row } from "react-bootstrap";

const SubscriptionListHeader = ({ type, headerValue }) => {
  return (
    <div style={{ marginTop: "3%", marginLeft: "1%", marginBottom: "2%" }}>
      <Row>
        {headerValue.map((title, index) => {
          return (
            <Col xl={title.col} lg={title.col} key={index}>
              <label style={{ fontSize: "16px", fontWeight: "600" }}>
                {title.title}
              </label>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SubscriptionListHeader;
