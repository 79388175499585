import { gql } from "@apollo/client";

export const CATEGORY_SEARCH = gql`
  query categorySearch($query: String) {
    categorySearch(query: $query) {
      _id
      name
      interestsCount
      isChecked
      subCategories {
        _id
        name
      }
      subCategoryCount
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $createProductInput: createProductInput
    $categories: [CategoryAdd]
  ) {
    createProduct(
      createProductInput: $createProductInput
      categories: $categories
    ) {
      success
      message
    }
  }
`;

export const FETCH_PROFILE_PRODUCTS = gql`
  query fetchProfileProducts($profileId: String) {
    fetchProfileProducts(profileId: $profileId) {
      _id
      title
      price
      discount
      medias {
        key
        uri
        width
        height
        mediaType
        uri_thumbnail
      }
      finalTotal
    }
  }
`;

export const FETCH_PRODUCT_FOR_EDIT = gql`
  query fetchProductForEdit($productId: String) {
    fetchProductForEdit(productId: $productId) {
      _id
      user_id
      sku
      title
      price
      discount
      companyName
      medias {
        key
        uri
        width
        height
        mediaType
        uri_thumbnail
      }
      description
      productDetails
      totalReview
      totalRating
      finalTotal
      itemType
      quantity
      quantityLimitation
      categories {
        _id
        name
        subCategories {
          _id
          name
        }
      }
      variants {
        variantId
        variantType
        variantOptions
      }
      variantCombinations {
        combination
        price
        quantity
        quantityLimitation
        sku
        discount
        finalTotal
      }
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation editProduct(
    $editProductInput: editProductInput
    $categories: [CategoryAdd]
  ) {
    editProduct(editProductInput: $editProductInput, categories: $categories) {
      success
      message
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($productId: String) {
    deleteProduct(productId: $productId) {
      success
      message
    }
  }
`;

export const FETCH_SUBCATEGORIES_BY_CATEGORY = gql`
  query fetchSubCategoriesByCategoryId($categoryId: String) {
    fetchSubCategoriesByCategoryId(categoryId: $categoryId) {
      _id
      name
    }
  }
`;

export const FETCH_BUSINESS_PRODUCT_FOR_AUTO_SHIP = gql`
  query fetchBusinessProductsForAutoShip(
    $businessId: String
    $sellingPlanId: String
    $searchKeyword: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    fetchBusinessProductsForAutoShip(
      businessId: $businessId
      sellingPlanId: $sellingPlanId
      searchKeyword: $searchKeyword
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      products {
        _id
        title
        finalTotal
        price
        discount
        medias {
          uri
        }
        variantCombinationsCount
        isChecked
      }
      totalCount
    }
  }
`;
