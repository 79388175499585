import { Col, Modal } from "react-bootstrap";
import { netural400, neturalBlack } from "../../constants/color";
import { IoCloseSharp } from "react-icons/io5";
import SearchBar from "../search-bar/search-bar.component";
import CustomLabel from "../shared/label/custom-label.component";
import CustomButton from "../button/button.component";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { FETCH_BUSINESS_PRODUCT_FOR_AUTO_SHIP } from "../../services/graphql/product";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import Pagination from "../pagination/pagination.component";
import SubscriptionProductListDesign from "../subscription-product-list-design/subscription-product-list-design.component";

const SelectProductModel = (props) => {
  const { sellingPlanId } = props;
  const businesprofile = useSelector((state) => state.businesprofile);
  const [products, setProducts] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [selectedProducts, setSelectedProducts] = useState(
    props.selectedProducts
  );
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 20;
  const [totalCount, setTotalCount] = useState(0);

  const [
    fetchBusinessProductsForAutoShip,
    { loading: loadingBusinessProductsForAutoShip },
  ] = useLazyQuery(FETCH_BUSINESS_PRODUCT_FOR_AUTO_SHIP, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchBusinessProductsForAutoShip) {
        let product = data.fetchBusinessProductsForAutoShip.products;
        if (product.length > 0) {
          const selectedProductMap = new Map(
            selectedProducts.map((item) => [item._id, item])
          );

          const updatedProduct = product.map((item) => ({
            ...item,
            isChecked: selectedProductMap.has(item._id) ? true : false,
          }));
          // console.log("Selling plan products", product);
          setProducts(updatedProduct);
          setTotalCount(
            Math.ceil(
              data.fetchBusinessProductsForAutoShip.totalCount / pageSize
            )
          );
        } else {
          setProducts([]);
        }
      }
    },
    onError: (error) => {
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });

  const getProductData = () => {
    fetchBusinessProductsForAutoShip({
      variables: {
        businessId: businesprofile._id,
        sellingPlanId: sellingPlanId,
        searchKeyword: searchWord,
        pageNumber,
        pageSize,
      },
    });
  };

  useEffect(() => {
    getProductData();
  }, [pageNumber, searchWord]);

  const handleOnChange = (productId) => {
    setProducts((prevProduct) =>
      prevProduct.map((item) =>
        item._id === productId._id
          ? { ...item, isChecked: !item.isChecked }
          : item
      )
    );

    setSelectedProducts((prevSelectedProduct) => {
      const isProductSelected = prevSelectedProduct.some(
        (selected) => selected._id === productId._id
      );

      if (isProductSelected) {
        return prevSelectedProduct.filter(
          (selected) => selected._id !== productId._id
        );
      } else {
        const selectedItem = products.find(
          (item) => item._id === productId._id
        );
        return [...prevSelectedProduct, { ...selectedItem, isChecked: true }];
      }
    });
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header
        style={{
          borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
          paddingLeft: "3%",
          paddingRight: "3%",
          backgroundColor: "white",
          borderRadius: "12px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          paddingBottom: "2%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <label
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: neturalBlack,
            }}
          >
            Add products
          </label>
        </Modal.Title>
        <Modal.Title id="contained-modal-title-vcenter">
          <label
            style={{
              fontSize: "14px",
              color: netural400,
            }}
          >
            All product variants are included
          </label>
        </Modal.Title>
        <IoCloseSharp
          size={30}
          color={netural400}
          onClick={() => {
            props.onHide();
          }}
        />
      </Modal.Header>
      <div style={{ borderTop: "1px solid #dadada" }}></div>
      <Modal.Body
        style={{
          paddingTop: "3%",
          backgroundColor: "white",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
        }}
      >
        <SearchBar
          handleClear={() => {
            setSelectedProducts([]);
            setSearchWord("");
            setPageNumber(1);
            if (searchWord === "" && pageNumber === 1) {
              getProductData();
            }
          }}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setPageNumber={setPageNumber}
        />
        <div style={{ borderTop: "1px solid #dadada" }}></div>
        <div style={{ height: "50vh", overflow: "auto" }}>
          {loadingBusinessProductsForAutoShip ? (
            <div style={{ marginTop: "10%" }}>
              <LoadingSpinner overlay={false} />
            </div>
          ) : !loadingBusinessProductsForAutoShip && products.length === 0 ? (
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "50vh",
              }}
            >
              <CustomLabel
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: neturalBlack,
                }}
              >
                No product found. Please try using different keywords.
              </CustomLabel>
            </Col>
          ) : (
            products.map((allProduct, index) => {
              return (
                <SubscriptionProductListDesign
                  allProduct={allProduct}
                  products={products}
                  index={index}
                  handleOnChange={handleOnChange}
                  showCheckBox={true}
                  showPrice={true}
                  showDelete={false}
                  showEdit={false}
                  imageColValue={8}
                />
              );
            })
          )}
        </div>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "2%" }}
        >
          {!loadingBusinessProductsForAutoShip && products.length !== 0 ? (
            <Pagination
              pageNumber={pageNumber}
              totalPages={totalCount}
              siblings={1}
              itemsPerPage={pageSize}
              leftAndRightSkip={3}
              onPageChange={(page) => {
                setPageNumber(page);
              }}
            />
          ) : null}
        </Col>
        <div style={{ borderTop: "1px solid #dadada", marginTop: "3%" }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: "4%",
          }}
        >
          <Col lg="7" md="7" sm="8" xs="8">
            <CustomLabel
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: neturalBlack,
                width: "70%",
              }}
            >
              {selectedProducts.length} product(s) selected
            </CustomLabel>
          </Col>
          <Col
            lg="1"
            md="1"
            sm="1"
            xs="1"
            className="mt-3 mb-3"
            style={{ marginRight: "3%" }}
          >
            <CustomButton
              buttonType="secondary"
              title={"Cancel"}
              handleButtonClick={() => props.onHide()}
              customStyle={{ height: "48px" }}
            />
          </Col>
          <Col lg="3" md="3" sm="2" xs="2" className="mt-3 mb-3">
            <CustomButton
              title={"Add products"}
              customStyle={{ marginTop: "0px", padding: "8px" }}
              handleButtonClick={() => props.handleAddProduct(selectedProducts)}
              buttonType="validation"
              isValid={selectedProducts.length === 0 ? false : true}
            />
          </Col>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectProductModel;
