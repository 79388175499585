import { Fragment, useEffect, useState } from "react";
import HeaderSection from "../header/header.component";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import CustomLinkButtonWithIcon from "../link-button-with-icon/link-button-with-icon.component";
import { BsSearch, BsXLg } from "react-icons/bs";
import SellingPlanListCard from "../selling-plan-list-card/selling-plan-list-card.component";
import {
  FETCH_BUSINESS_SELLING_PLAN_SUBSCRIPTION_LIST,
  FETCH_SELLING_PLAN_LIST,
} from "../../services/graphql/subscription";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const PAGE_SIZE_FOR_SALES_LIST = 15;

const SubscriptionList = () => {
  const businesprofile = useSelector((state) => state.businesprofile);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const sellingPlanTitle = [
    { title: "Sr no", col: 3 },
    { title: "Name", col: 3 },
    { title: "Discount (%)", col: 3 },
    { title: "Action", col: 3 },
  ];
  const subscriptionTitle = [
    { title: "Sr no", col: 2 },
    { title: "Product name", col: 2 },
    { title: "Status", col: 2 },
    { title: "Client", col: 2 },
    { title: "Order date", col: 2 },
    { title: "Action", col: 2 },
  ];
  const [sellingPlanList, setSellingPlanList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [type, setType] = useState("Selling plans");
  const totalPages = Math.ceil(totalCount / PAGE_SIZE_FOR_SALES_LIST);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (type === "Selling plans") {
      fetchSellingPlanList({
        variables: {
          businessId: businesprofile._id,
          search,
          pageNumber: page,
          pageSize: PAGE_SIZE_FOR_SALES_LIST,
        },
      });
    } else {
      fetchBusinessSellingPlansSubscriberList({
        variables: {
          providerId: businesprofile._id,
          search,
          pageNumber: page,
          pageSize: PAGE_SIZE_FOR_SALES_LIST,
        },
      });
    }
  }, [search, page, type]);

  useEffect(() => {
    setPage(1);
  }, [type]);

  const [
    fetchBusinessSellingPlansSubscriberList,
    { loading: loadingBusinessSellingPlanList },
  ] = useLazyQuery(FETCH_BUSINESS_SELLING_PLAN_SUBSCRIPTION_LIST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchBusinessSellingPlansSubscriberList) {
        const businessSellingPlan =
          data.fetchBusinessSellingPlansSubscriberList;
        setTotalCount(businessSellingPlan.totalCount);
        setSubscriptionList(businessSellingPlan.SellingPlanSubscriberList);
        // console.log("Final value-----------------", businessSellingPlan);
      }
    },
  });

  const [fetchSellingPlanList, { loading: loadingSellingPlanList }] =
    useLazyQuery(FETCH_SELLING_PLAN_LIST, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchSellingPlanList) {
          let sellingPlan = data.fetchSellingPlanList;
          setSellingPlanList(sellingPlan.sellingPlanList);
          setTotalCount(sellingPlan.totalCount);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  return (
    <div className="main-div-right-section">
      <Fragment>
        {/* Page header section with searcbh and profile */}
        <HeaderSection currentTab={"subscription"} />
        <div className="second-section-style">
          <Row>
            <Col
              lg="10"
              md="8"
              sm="6"
              xs="6"
              style={{
                paddingLeft: "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: "22px", fontWeight: "bold" }}>
                Subscription
              </label>
            </Col>
            {type === "Selling plans" && (
              <Col xl="2" lg="2" style={{ paddingLeft: "0px" }}>
                <CustomLinkButtonWithIcon
                  title={"Create selling plan"}
                  linkToNavigate={"create"}
                />
              </Col>
            )}
          </Row>
          <div style={{ marginTop: "3%" }}>
            <Row>
              <Col lg="3" md="3" sm="2" xs="2" style={{ paddingLeft: "0px" }}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup
                    style={
                      totalCount === 0 && search === ""
                        ? { backgroundColor: "lightgrey" }
                        : { backgroundColor: "white" }
                    }
                  >
                    <BsSearch color="#7d7d7d" />
                    <Form.Control
                      type="text"
                      style={
                        totalCount === 0 && search === ""
                          ? { backgroundColor: "lightgrey" }
                          : { backgroundColor: "white" }
                      }
                      disabled={totalCount === 0 && search === ""}
                      placeholder="Search subscriptions"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {search ? (
                      <BsXLg
                        onClick={() => {
                          setSearch("");
                        }}
                        color="#7d7d7d"
                      />
                    ) : null}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "3%" }}>
            <SellingPlanListCard
              totalCount={totalCount}
              sellingPlanList={
                type === "Selling plans" ? sellingPlanList : subscriptionList
              }
              page={page}
              type={type}
              setType={setType}
              setPage={setPage}
              totalPages={totalPages}
              pageSize={PAGE_SIZE_FOR_SALES_LIST}
              loading={
                type === "Selling plans"
                  ? loadingSellingPlanList
                  : loadingBusinessSellingPlanList
              }
              search={search}
              dropDownValue={["Selling plans", "Subscriptions"]}
              headerValue={
                type === "Selling plans" ? sellingPlanTitle : subscriptionTitle
              }
            />
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default SubscriptionList;
