import { useLazyQuery, useMutation } from "@apollo/client";
import HeaderSection from "../header/header.component";
import { React, useState, useEffect, Fragment } from "react";
import { Col, Row, Tab, ListGroup, Image } from "react-bootstrap";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import EmptyProductList from "../../components/empty-product-list/empty-product-list.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoTrashOutline } from "react-icons/io5";
import { netural400 } from "../../constants/color";
import {
  DELETE_PRODUCT,
  FETCH_PROFILE_PRODUCTS,
} from "../../services/graphql/product";
import LoadingSpinner from "../../components/shared/loading-spinner/loading-spinner.component";
import { FaRegEdit } from "react-icons/fa";
import ModalPopup from "../modal-popup/modal-popup.component";
import CustomLinkButtonWithIcon from "../../components/link-button-with-icon/link-button-with-icon.component";

const ProductList = () => {
  const businesprofile = useSelector((state) => state.businesprofile);
  const [products, setProducts] = useState([]);
  // const [displayEmptyScreen, setDisplayEmptyScreen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");

  useEffect(() => {
    getProductsList();
  }, []);
  const [fetchProfileProducts, { loading: loadingProductsFetch }] =
    useLazyQuery(FETCH_PROFILE_PRODUCTS, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchProfileProducts) {
          let product = data.fetchProfileProducts;
          if (product.length > 0) {
            setProducts(product);
          } else {
            setProducts([]);
            // setDisplayEmptyScreen(true);
          }
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  const [deletePortalProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: (data) => {
      if (data.deleteProduct.success) {
        toast.success(data.deleteProduct.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
        getProductsList();
        // setSelectedTab("Services")
      } else {
        toast.error(data.deleteProduct.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      }
    },
    onError: (error) => {
     // console.log(error, "ERROR==============");
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });
  const getProductsList = () => {
    fetchProfileProducts({
      variables: {
        profileId: businesprofile.user_id,
      },
    });
  };

  const handleDeleteClick = (productId) => {
    setModalShow(true);
    setSelectedProductId(productId);
  };

  return (
    <div className="main-div-right-section">
      <Fragment>
        {/* Page header section with searcbh and profile */}
        <HeaderSection currentTab={"Product"} />
        {!loadingProductsFetch && products.length === 0 ? (
          <EmptyProductList />
        ) : (
          <div className="second-section-style">
            <Fragment>
              <Row>
                <Col
                  lg="10"
                  md="8"
                  sm="6"
                  xs="6"
                  style={{
                    paddingLeft: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Products
                  </label>
                </Col>
                <Col lg="2" md="4" sm="6" xs="6" style={{ paddingLeft: "0px" }}>
                  <CustomLinkButtonWithIcon
                    title={"Add Product"}
                    linkToNavigate={"create"}
                  />
                </Col>
              </Row>
              <Tab.Container>
                <div style={{ marginTop: "3%" }}>
                  {loadingProductsFetch ? (
                    <LoadingSpinner size="medium" overlay={false} />
                  ) : products && products.length > 0 ? (
                    <Row>
                      <Col
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        style={{ paddingLeft: "0px" }}
                      >
                        <ListGroup style={{ borderRadius: 12 }}>
                          {products.map((product, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col
                                  lg="1"
                                  md="2"
                                  sm="2"
                                  xs="2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    src={`${process.env.REACT_APP_HOST_URL}${product.medias[0].uri}`}
                                    alt="Service Image"
                                    style={{
                                      width: "78px",
                                      height: "78px",
                                      borderRadius: "12px",
                                    }}
                                  />
                                </Col>
                                <Col
                                  lg="10"
                                  md="8"
                                  sm="6"
                                  xs="6"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {product.title} <br />
                                    {product.price === product.finalTotal ? (
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          fontSize: "14px",
                                          fontWeight: "normal",
                                          color: netural400,
                                        }}
                                      >
                                        {product.price}
                                      </p>
                                    ) : product.finalTotal === "0" ? (
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          fontSize: "14px",
                                          fontWeight: "normal",
                                          color: netural400,
                                        }}
                                      >
                                        {product.price}
                                      </p>
                                    ) : (
                                      <div style={{ display: "flex" }}>
                                        <p
                                          style={{
                                            marginBottom: "0px",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            color: netural400,
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          ${product.price}
                                        </p>
                                        <p
                                          style={{
                                            marginBottom: "0px",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            color: netural400,
                                            marginLeft: 8,
                                          }}
                                        >
                                          ${product.finalTotal}
                                        </p>
                                      </div>
                                    )}
                                  </p>
                                </Col>
                                <Col
                                  lg="1"
                                  md="1"
                                  sm="2"
                                  xs="2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Link to={`edit/${product._id}`}>
                                    <IconContext.Provider
                                      value={{ size: 24, color: "black" }}
                                    >
                                      <FaRegEdit />
                                    </IconContext.Provider>
                                  </Link>
                                  <div style={{ marginLeft: "15%" }}></div>
                                  <div
                                    onClick={() => {
                                      handleDeleteClick(product._id);
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{ size: 24, color: "black" }}
                                    >
                                      <IoTrashOutline />
                                    </IconContext.Provider>
                                  </div>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Tab.Container>
            </Fragment>
            <ModalPopup
              show={modalShow}
              onHide={() => {
                setModalShow(false);
              }}
              onCancelPress={() => {
                setModalShow(false);
              }}
              onSubmit={async () => {
                setModalShow(false);
                await deletePortalProduct({
                  variables: {
                    productId: selectedProductId,
                  },
                });
              }}
              buttonType={"Delete"}
              title={"Delete Product"}
              body={"Are you sure you want to delete this product? "}
              body2={"This cannot be undone."}
              submitButton={"Delete Product"}
            />
          </div>
        )}
      </Fragment>
    </div>
  );
};
export default ProductList;
