import { useState } from "react";
import HeaderSection from "../header/header.component";
import SubscriptionGeneralInfoForm from "../subscription-general-info-form/subscription-general-info-form.component";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SubscriptionSelectProductPage from "../subscription-select-product-page/subscription-select-product-page.component";

const CreateSubscriptionForm = () => {
  const navigate = useNavigate();
  const [stepsCount, setSetpsCount] = useState(1);
  const [formData, setFormData] = useState({
    sellingPlanName: "",
    productDiscount: "",
    sellingPlanFrequency: "",
    minimumBillingCycle: "",
    selectedProducts: [],
    completeProcess: false,
  });

  const setGeneralInfo = (
    sellingPlanName,
    productDiscount,
    sellingPlanFrequency,
    minimumBillingCycle
  ) => {
    setFormData({
      ...formData,
      sellingPlanName,
      productDiscount,
      sellingPlanFrequency,
      minimumBillingCycle,
    });
  };

  const setSelectedProductsValue = (selectedProducts) => {
    setFormData({
      ...formData,
      selectedProducts,
    });
  };

  const handleContinueClick = () => {
    setSetpsCount(stepsCount + 1);
  };

  const handlePageBackClick = () => {
    setSetpsCount(stepsCount - 1);
  };

  const handleBrowserBackButton = () => {
    if (stepsCount > 1) {
      setSetpsCount(stepsCount - 1);
    } else {
      navigate(-1);
    }
    window.addEventListener("popstate", handleBrowserBackButton);
    return () => {
      window.removeEventListener("popstate", handleBrowserBackButton);
    };
  };

  const formComponents = [
    {
      component: SubscriptionGeneralInfoForm,
      props: {
        handleNextClick: handleContinueClick,
        handleBackButton: handleBrowserBackButton,
        setGeneralInfo: setGeneralInfo,
        formValue: formData,
      },
    },
    {
      component: SubscriptionSelectProductPage,
      props: {
        handleBackClick: handlePageBackClick,
        setSelectedProductsValue: setSelectedProductsValue,
        formValue: formData,
      },
    },
  ];

  const ProgressIndicator = () => {
    return (
      <Row>
        <Col md="12" lg="12" style={{ display: "flex" }}>
          {formComponents.map((_, index) => (
            <Col lg="6" md="6" key={index}>
              <div
                style={{
                  height: 3,
                  backgroundColor: stepsCount >= index + 1 ? "#FF8D24" : null,
                }}
              ></div>
            </Col>
          ))}
        </Col>
      </Row>
    );
  };

  const CurrentFormStep = formComponents[stepsCount - 1];

  return (
    <div
      style={{
        backgroundColor: "white",
        flexDirection: "row",
        display: "flex",
        height: "99vh",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div style={{ backgroundColor: "white", width: "100%" }}>
        {/* Page header section with searcbh and profile */}
        <HeaderSection currentTab={"subscription"} />
        <div style={{ height: "89%" }}>
          <ProgressIndicator />
          <div style={{ marginLeft: "5%" }}>
            {CurrentFormStep ? (
              <CurrentFormStep.component {...CurrentFormStep.props} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSubscriptionForm;
