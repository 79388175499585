import { gql } from "@apollo/client";

export const FETCH_SALES_LIST = gql`
  query fetchSalesList(
    $providerId: ID!
    $page: Int!
    $pageSize: Int!
    $type: String!
    $search: String
  ) {
    fetchSalesList(
      providerId: $providerId
      page: $page
      pageSize: $pageSize
      type: $type
      search: $search
    ) {
      totalCount
      salesList {
        id
        name
        date
      }
    }
  }
`;

export const FETCH_ORDER_DETAILS = gql`
  query fetchOrderDetails($transactionId: String, $providerId: String) {
    fetchOrderDetails(transactionId: $transactionId, providerId: $providerId) {
      userInfo {
        id
        userName
        contactNumber
        email
      }
      orderInfo {
        orderId
        orderDate
        scheduleDate
        serviceDuration
        serviceMethod
      }
      type
      paymentSummary {
        itemInfo {
          _id
          title
          price
          uri
          quantity
          checkReview
          variantCombination
        }
        coupon {
          code
          discountPercentage
          discountedAmount
          finalAmount
          type
          stripeId
        }
        itemPrice
        subscriptionPoints
        shippingCost
        platformFees
        platformFeesRate
        taxAmount
        taxRate
        stripeTransactionFees
        transactionFees
        totalAmount
        netIncome
        bookedSpots
      }
      deliveryAddress {
        streetName
        cityName
        stateAndZipCode
        country
      }
      inviteesInfo {
        bookedSpots
        invitees
      }
      fullName
    }
  }
`;
