import React from "react";
import { neturalBlack } from "../../../constants/color";

const CustomLabel = ({ children, style: userStyle, ...restProps }) => {
  const defaultStyle = {
    fontSize: "16px",
    fontWeight: "500",
    color: neturalBlack,
  };

  const combinedStyle = { ...defaultStyle, ...userStyle };

  return (
    <label style={combinedStyle} {...restProps}>
      {children}
    </label>
  );
};

export default CustomLabel;
