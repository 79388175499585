import { Col, Form, Row } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400 } from "../../constants/color";
import { FaRegEdit } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";

const SubscriptionProductListDesign = (props) => {
  const {
    allProduct,
    products,
    index,
    handleOnChange,
    showCheckBox,
    showPrice,
    showDelete,
    showEdit,
    imageColValue,
    handleEdit,
    handleDelete,
  } = props;
  return (
    <div style={{ marginTop: "3%" }} key={index}>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {showCheckBox && (
          <Col xl={1} lg={1}>
            <Form.Check
              inline
              //   label={}
              name="group1"
              type={"checkbox"}
              //   id={index}
              style={{ marginBottom: "3%" }}
              checked={allProduct.isChecked}
              onChange={() => {
                handleOnChange(allProduct);
              }}
            />
          </Col>
        )}
        <Col xl={imageColValue} lg={imageColValue}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              // src="../images/Profile.jpg"
              src={`${process.env.REACT_APP_HOST_URL}${allProduct.medias[0].uri}`}
              alt="product name"
              style={{
                aspectRatio: "1/1",
                width: "",
                height: "7vh",
                borderRadius: "12px",
              }}
            />
            <div
              style={{
                marginLeft: "2%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CustomLabel
                style={{
                  fontSize: "14px",
                  color: netural400,
                }}
              >
                {allProduct.title}
              </CustomLabel>
              {allProduct.variantCombinationsCount > 0 && (
                <CustomLabel
                  style={{
                    fontSize: "12px",
                    color: netural400,
                  }}
                >
                  variants: {allProduct.variantCombinationsCount}
                </CustomLabel>
              )}
            </div>
          </div>
        </Col>
        {showPrice && (
          <Col
            xl={3}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "row",
            }}
          >
            {allProduct.price === allProduct.finalTotal ? (
              <CustomLabel
                style={{
                  marginBottom: "0px",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: netural400,
                }}
              >
                {allProduct.price}
              </CustomLabel>
            ) : allProduct.finalTotal === "0" ? (
              <CustomLabel
                style={{
                  marginBottom: "0px",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: netural400,
                }}
              >
                {allProduct.price}
              </CustomLabel>
            ) : (
              <CustomLabel
                style={{
                  marginBottom: "0px",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: netural400,
                  marginLeft: 8,
                }}
              >
                ${allProduct.finalTotal}
              </CustomLabel>
            )}
          </Col>
        )}
        {showDelete || showEdit ? (
          <Col
            xl={2}
            lg={2}
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "row",
            }}
          >
            {showEdit && (
              <FaRegEdit
                size={24}
                color="black"
                style={{ marginRight: "5%" }}
                onClick={() => handleEdit(allProduct)}
              />
            )}
            {showDelete && (
              <IoTrashOutline
                size={24}
                color="black"
                onClick={() => handleDelete(allProduct)}
                style={!showEdit ? { marginRight: "3%" } : null}
              />
            )}
          </Col>
        ) : null}
      </Row>
      {products?.length !== index + 1 && (
        <div
          style={{
            borderTop: "1px solid #dadada",
            marginTop: "3%",
          }}
        ></div>
      )}
    </div>
  );
};

export default SubscriptionProductListDesign;
