import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  error50,
  error900,
  netural400,
  netural50,
  neturalBlack,
  primaryOrange,
  success50,
  success900,
} from "../../constants/color";
import CustomLabel from "../shared/label/custom-label.component";
import { IoArrowForward } from "react-icons/io5";
import CustomButton from "../button/button.component";
import SubscriptionDetialsSection from "../subscription-details-section/subscription-details-section.component";
import ShippingAddressSection from "../shipping-address-section/shipping-address-section.component";
import OrderSummary from "../order-summary/order-summary.component";
import HeaderSection from "../header/header.component";
import SubscriptionOrderDetailsModal from "../subscription-order-details-modal/subscription-order-details-modal.component";
import OrderSummaryPricing from "../order-summary-pricing/order-summary-pricing.component";
import PaymentDetail from "../payment-detail/payment-detail.component";
import CustomerCard from "../customer-card/customer-card.component";
import SalesProductList from "../sales-product-list/sales-product-list.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CANCEL_PRODUCT_SUSBCRIPTION,
  FETCH_SUBSCRIPTION_PRODUCT_DETAILS_BUSINESS,
} from "../../services/graphql/subscription";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { useSelector } from "react-redux";
import ModalPopup from "../modal-popup/modal-popup.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubscriptionDetailsPage = () => {
  const params = useParams();
  const { subscriptionId } = params;
  const businesprofile = useSelector((state) => state.businesprofile);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [subscriptionProductDetails, setSubscriptionProductDetails] = useState(
    {}
  );
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [orderScheduleInfo, setOrderScheduleInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [customerName, setCustomerName] = useState("");
  const [cancelInfo, setCancleInfo] = useState(null);
  const [media, setMedia] = useState([]);
  const [showCancelSubscriptionModal, setCancelSubscriptionModal] =
    useState(false);

  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionDetails(subscriptionId);
    }
  }, [subscriptionId]);

  const getSubscriptionDetails = async (subscriptionId) => {
    await fetchSubscribeProductDetailsForBusiness({
      variables: {
        subscriptionId: atob(subscriptionId),
      },
    });
  };
  const [
    fetchSubscribeProductDetailsForBusiness,
    { loading: loadingSubscriptionProductDetails },
  ] = useLazyQuery(FETCH_SUBSCRIPTION_PRODUCT_DETAILS_BUSINESS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchSubscribeProductDetailsForBusiness) {
        const subscriptionProduct =
          data.fetchSubscribeProductDetailsForBusiness;
        const itemInfo = subscriptionProduct.paymentSummary.itemInfo;
        setSubscriptionProductDetails(subscriptionProduct);
        setUserInfo(subscriptionProduct.customer);
        setSubscriptionDetails(subscriptionProduct.subscriptionDetails);
        setCustomerName(subscriptionProduct.fullName);
        setShippingAddress(subscriptionProduct.deliveryAddress);
        setOrderScheduleInfo(subscriptionProduct.orderScheduleInfo);
        setCheckoutInfo(subscriptionProduct.paymentSummary);
        setMedia(itemInfo);
        setCancleInfo(subscriptionProduct.cancel);
      }
    },
  });
  const [cancelProductSubscription, { loading: cancellingSubscription }] =
    useMutation(CANCEL_PRODUCT_SUSBCRIPTION, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.cancelProductSubscription) {
          console.log("SUBSCRIPTION CANCELLED", data.cancelProductSubscription);
          getSubscriptionDetails(subscriptionId);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  return loadingSubscriptionProductDetails || cancellingSubscription ? (
    <LoadingSpinner overlay />
  ) : (
    <div
      className="main-div-right-section"
      style={{ backgroundColor: "white" }}
    >
      <Fragment>
        <HeaderSection currentTab={"Sales"} />
        <Fragment>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col xl={11} lg={11} md={11} sm={12} xs={12}>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    marginTop: "2%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2%",
                  }}
                >
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "3%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1%",
                        }}
                      >
                        <Link
                          to={"/subscription"}
                          style={{
                            fontSize: "16px",
                            color: netural400,
                          }}
                        >
                          Subscription
                        </Link>
                        <CustomLabel
                          style={{
                            marginLeft: "1%",
                            marginRight: "1%",
                            color: neturalBlack,
                          }}
                        >
                          {">"}
                        </CustomLabel>
                        <CustomLabel
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: neturalBlack,
                          }}
                        >
                          ID #{atob(subscriptionId)}
                        </CustomLabel>{" "}
                      </div>
                      <CustomLabel
                        style={{
                          fontSize: "22px",
                          fontWeight: "bold",
                          color: neturalBlack,
                        }}
                      >
                        ID #{atob(subscriptionId)}
                      </CustomLabel>{" "}
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {subscriptionDetails.status !== "cancelled" ? (
                      <CustomButton
                        title={"Cancel subscription"}
                        buttonType={"secondary"}
                        handleButtonClick={() => {
                          setCancelSubscriptionModal(true);
                        }}
                      />
                    ) : null}
                  </Col>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CustomerCard userInfo={userInfo} />
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <SubscriptionDetialsSection
                      subscriptionDetails={subscriptionDetails}
                      cancelInfo={cancelInfo}
                    />
                  </Col>
                  <div
                    style={{
                      borderBottom: "1px solid rgb(218, 218,218)",
                      marginTop: "3%",
                    }}
                  ></div>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ShippingAddressSection
                      shippingAddress={shippingAddress}
                      name={customerName}
                    />
                  </Col>
                  <div
                    style={{
                      borderBottom: "1px solid #dadada",
                      marginTop: "3%",
                    }}
                  ></div>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginTop: "3%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CustomLabel
                          style={{ fontWeight: "600", fontSize: "20px" }}
                        >
                          Order schedule
                        </CustomLabel>
                        <Link
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: primaryOrange,
                            marginLeft: "2%",
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            setShowOrderDetails(true);
                          }}
                        >
                          View orders details
                          <IoArrowForward color={primaryOrange} size={18} />
                        </Link>
                      </div>
                      <div style={{ marginTop: "3%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <CustomLabel
                            style={{
                              fontWeight: "500",
                              color: neturalBlack,
                              fontSize: "16px",
                            }}
                          >
                            Last order status
                          </CustomLabel>
                          <CustomLabel
                            style={{
                              color:
                                orderScheduleInfo.lastOrderStatus ===
                                "successful"
                                  ? success900
                                  : orderScheduleInfo.lastOrderStatus ===
                                      "cancelled" ||
                                    orderScheduleInfo.lastOrderStatus ===
                                      "unsucccessful"
                                  ? error900
                                  : neturalBlack,
                              fontSize: "16px",
                              backgroundColor:
                                orderScheduleInfo.lastOrderStatus ===
                                "successful"
                                  ? success50
                                  : orderScheduleInfo.lastOrderStatus ===
                                      "cancelled" ||
                                    orderScheduleInfo.lastOrderStatus ===
                                      "unsucccessful"
                                  ? error50
                                  : netural50,
                              borderRadius: "12px",
                              paddingLeft: "2%",
                              paddingRight: "2%",
                              textTransform: "capitalize",
                            }}
                          >
                            {orderScheduleInfo.lastOrderStatus}
                          </CustomLabel>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "2%",
                          }}
                        >
                          <CustomLabel
                            style={{
                              fontWeight: "500",
                              color: neturalBlack,
                              fontSize: "16px",
                            }}
                          >
                            Last order
                          </CustomLabel>
                          <CustomLabel
                            style={{
                              color: netural400,
                              fontSize: "16px",
                            }}
                          >
                            {orderScheduleInfo.lastOrderDate}
                          </CustomLabel>
                        </div>
                        {orderScheduleInfo.nextOrderDate && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: "2%",
                            }}
                          >
                            <CustomLabel
                              style={{
                                fontWeight: "500",
                                color: neturalBlack,
                                fontSize: "16px",
                              }}
                            >
                              Next order
                            </CustomLabel>
                            <CustomLabel
                              style={{
                                color: netural400,
                                fontSize: "16px",
                              }}
                            >
                              {orderScheduleInfo.nextOrderDate}
                            </CustomLabel>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <div
                    style={{
                      borderBottom: "1px solid rgb(218, 218,218)",
                      marginTop: "3%",
                      marginBottom: "3%",
                    }}
                  ></div>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PaymentDetail
                      paymentDetail={checkoutInfo}
                      type={"product"}
                    />
                  </Col>
                  <div
                    style={{
                      borderBottom: "1px solid rgb(218, 218,218)",
                      marginTop: "3%",
                      marginBottom: "3%",
                    }}
                  ></div>
                </Col>
                <Col
                  xl={1}
                  lg={1}
                  md={1}
                  style={{ display: "flex", justifyContent: "center" }}
                ></Col>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={5}
                  style={{ marginTop: "2%" }}
                >
                  <SalesProductList media={media} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Fragment>
        {showOrderDetails && (
          <SubscriptionOrderDetailsModal
            show={showOrderDetails}
            onHide={() => {
              setShowOrderDetails(false);
            }}
            subscriptionid={subscriptionProductDetails._id}
          />
        )}
        {showCancelSubscriptionModal && (
          <ModalPopup
            show={showCancelSubscriptionModal}
            onHide={() => {
              setCancelSubscriptionModal(false);
            }}
            onCancelPress={() => {
              setCancelSubscriptionModal(false);
            }}
            onSubmit={async (cancelReason) => {
              let cancelProductSubscriptionInput = {
                subscriptionId: subscriptionProductDetails._id,
                cancelByInfoInput: {
                  id: businesprofile._id,
                  name: businesprofile.name,
                  reason: cancelReason,
                  cancelBy: "business",
                },
              };
              setCancelSubscriptionModal(false);
              await cancelProductSubscription({
                variables: {
                  cancelProductSubscriptionInput:
                    cancelProductSubscriptionInput,
                },
              });
            }}
            buttonType={"Cancel subscription"}
            title={"Cancel subscription?"}
            body={"Are you sure you want to cancel this subscription? "}
            body2={"This cannot be undone."}
            submitButton={"Cancel subscription"}
          />
        )}
      </Fragment>
    </div>
  );
};

export default SubscriptionDetailsPage;
