import { gql } from "@apollo/client";

export const FETCH_SELLING_PLAN_LIST = gql`
  query fetchSellingPlanList(
    $businessId: String!
    $search: String!
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    fetchSellingPlanList(
      businessId: $businessId
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalCount
      sellingPlanList {
        _id
        planName
        discount
      }
    }
  }
`;

export const FETCH_BUSINESS_SELLING_PLAN_SUBSCRIPTION_LIST = gql`
  query fetchBusinessSellingPlansSubscriberList(
    $providerId: String
    $search: String!
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    fetchBusinessSellingPlansSubscriberList(
      providerId: $providerId
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalCount
      SellingPlanSubscriberList {
        _id
        createdAt
        type
        productTitle
        variantCombination
        status
        stripeSubscriptionId
        discount
        fullName
      }
    }
  }
`;

export const FETCH_SUBSCRIPTION_PRODUCT_DETAILS_BUSINESS = gql`
  query fetchSubscribeProductDetailsForBusiness($subscriptionId: String) {
    fetchSubscribeProductDetailsForBusiness(subscriptionId: $subscriptionId) {
      _id
      customer {
        email
        phoneNumber
        fullName
      }
      subscriptionDetails {
        status
        subscriptionId
        createdOn
        completedOrders
        delivery
        minimumCycles
        maximumCycles
      }
      fullName
      cancel {
        id
        name
        reason
        cancelBy
        cancelledAt
      }
      deliveryAddress {
        cityName
        country
        stateAndZipCode
        streetName
      }
      orderScheduleInfo {
        transactionId
        nextOrderDate
        lastOrderDate
        lastOrderStatus
      }
      allowToCancelSubscription
      paymentSummary {
        itemInfo {
          _id
          title
          price
          quantity
          variantCombination
          uri
        }
        itemPrice
        sellingPlan {
          sellingPlanDiscount
          discount
        }
        subscriptionPoints
        shippingCost
        platformFees
        platformFeesRate
        taxAmount
        taxRate
        stripeTransactionFees
        transactionFees
        totalAmount
        netIncome
      }
    }
  }
`;

export const FETCH_ALL_ORDER_SCHEDULE_DETAILS = gql`
  query fetchAllOrderScheduleDetails($subscriptionId: String) {
    fetchAllOrderScheduleDetails(subscriptionId: $subscriptionId) {
      id
      orderDate
      reason
      receiptURL
      status
      transactionId
    }
  }
`;

export const CANCEL_PRODUCT_SUSBCRIPTION = gql`
  mutation cancelProductSubscription(
    $cancelProductSubscriptionInput: CancelProductSubscriptionInput
  ) {
    cancelProductSubscription(
      cancelProductSubscriptionInput: $cancelProductSubscriptionInput
    ) {
      status
      message
      success
    }
  }
`;

export const FETCH_SELLING_PLAN_DETAILS = gql`
  query fetchSellingPlanDetails($businessId: String, $sellingPlanId: String) {
    fetchSellingPlanDetails(
      businessId: $businessId
      sellingPlanId: $sellingPlanId
    ) {
      minimumCycle
      planName
      user_id
      frequencyType
      discount
      createdAt
      businessId
      active
      products {
        _id
        title
        variantCombinationsCount
        medias {
          uri
        }
      }
      _id
    }
  }
`;

export const EDIT_SELLING_PLAN = gql`
  mutation editSellingPlan(
    $sellingPlanId: String
    $editSellingPlanInput: EditSellingPlanInput
  ) {
    editSellingPlan(
      sellingPlanId: $sellingPlanId
      editSellingPlanInput: $editSellingPlanInput
    ) {
      _id
    }
  }
`;

export const DELETE_SELLING_PLAN = gql`
  mutation deleteSellingPlan($sellingPlanId: String) {
    deleteSellingPlan(sellingPlanId: $sellingPlanId) {
      message
      success
    }
  }
`;
