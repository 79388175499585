import React from "react";
import { returnPaginationRange } from "../../constants/constant";
import "../pagination/pagination.styles.css";

const Pagination = ({
  pageNumber,
  totalPages,
  siblings,
  itemsPerPage,
  leftAndRightSkip,
  onPageChange,
}) => {
  const handleClick = (page, index) => {
    const rightDotPlace = 3 + 2 * siblings;
    if (index === rightDotPlace && page === "...") {
      onPageChange(pageNumber + leftAndRightSkip);
    } else if (index === 1 && page === "...") {
      onPageChange(pageNumber - leftAndRightSkip);
    } else {
      onPageChange(page);
    }
  };

  const paginatedArrayRange = returnPaginationRange(
    totalPages,
    pageNumber,
    siblings,
    itemsPerPage
  );

  return (
    <nav>
      <ul className="pagination justify-content-center">
        {/* <li className={`page-item ${pageNumber === 1 && 'disabled'}`}>
            <span className="page-link" onClick={() => handleClick(pageNumber - 1)}>&laquo;</span>
            </li> */}
        <li className={`page-item action ${pageNumber === 1 && "disabled"}`}>
          <span
            className="page-link"
            onClick={() => handleClick(pageNumber - 1)}
          >
            &lsaquo;
          </span>
        </li>
        {paginatedArrayRange.map((value, index) => {
          return (
            <li
              key={index}
              className={`page-item ${pageNumber === value && "active"}`}
            >
              <span
                className="page-link"
                onClick={() => handleClick(value, index)}
              >
                {value}
              </span>
            </li>
          );
        })}
        <li
          className={`page-item action ${
            pageNumber === totalPages && "disabled"
          }`}
        >
          <span
            className="page-link"
            onClick={() => handleClick(pageNumber + 1)}
          >
            &rsaquo;
          </span>
        </li>
        {/* <li className={`page-item ${pageNumber === totalPages && 'disabled'}`}>
            <span className="page-link" onClick={() => handleClick(pageNumber + 1)}>&raquo;</span>
            </li> */}
      </ul>
    </nav>
  );
};

export default Pagination;
