import SectionTitle from "../section-title/section-title.component";
import { Col, Form, Image, Row } from "react-bootstrap";
import { netural400, neturalBlack } from "../../constants/color";
import CustomLabel from "../shared/label/custom-label.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import { useEffect, useState } from "react";
import { monthlyMinimumCycleOptions, weeklyMinimumCycleOptions } from "../../constants/constant";

const SubscriptionGeneralInfoForm = ({
  handleNextClick,
  setGeneralInfo,
  formValue,
}) => {
  const [validated, setValidated] = useState(false);
  const [sellingPlanName, setSellingPlanName] = useState("");
  const [productDiscount, setProductDiscount] = useState(0);
  const [sellingPlanFrequency, setSellingPlanFrequency] = useState("Monthly");
  const [minimumBillingCycle, setMinimumBillingCycle] = useState(1);
  const [billingDropDown, setBillingDropDown] = useState([]);
  const [errorFields, setErrorFields] = useState({
    sellingPlanName: "",
  });

  useEffect(() => {
    if (sellingPlanFrequency === "Monthly") {
      setBillingDropDown(monthlyMinimumCycleOptions);
    } else if (sellingPlanFrequency === "Weekly") {
      setBillingDropDown(weeklyMinimumCycleOptions);
    }
  }, [sellingPlanFrequency]);

  useEffect(() => {
    if (formValue.sellingPlanName && formValue.sellingPlanName !== "") {
      const {
        sellingPlanName,
        productDiscount,
        sellingPlanFrequency,
        minimumBillingCycle,
      } = formValue;
      setSellingPlanName(sellingPlanName);
      setProductDiscount(productDiscount);
      setSellingPlanFrequency(sellingPlanFrequency);
      setMinimumBillingCycle(minimumBillingCycle);
    }
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const errors = {};

    if (sellingPlanName.trim().length === 0) {
      errors.sellingPlanName = "Please provide a selling plan name.";
    } else {
      setValidated(true);
      if (form.checkValidity() === true) {
        setGeneralInfo(
          sellingPlanName,
          productDiscount,
          sellingPlanFrequency,
          minimumBillingCycle
        );
        handleNextClick();
      }
    }
    setErrorFields(errors);
  };
  return (
    <div>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle
          title={"General Information"}
          subtitle={"Fill all fields below"}
        />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col xl={5} lg={5} md={5} style={{ marginBottom: "5%" }}>
            <Col md="12" lg="12">
              <Form.Group
                controlId="validationCustom01"
                style={{ color: netural400 }}
              >
                <Form.Label>Selling plan name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Selling plan name"
                  value={sellingPlanName}
                  onChange={(value) => {
                    if (value.target.value.trim().length !== 0) {
                      setErrorFields({ sellingPlanName: "" });
                      setSellingPlanName(value.target.value);
                    } else {
                      setSellingPlanName("");
                    }
                  }}
                  isInvalid={!!errorFields.sellingPlanName}
                />
                <Form.Control.Feedback type="invalid">
                  {errorFields.sellingPlanName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="12" lg="12" style={{ marginTop: "4%" }}>
              <Form.Group
                controlId="validationCustom01"
                style={{ color: netural400 }}
              >
                <Form.Label>Discount percent</Form.Label>
                <Form.Control
                  required
                  type="text"
                  inputMode="numeric"
                  placeholder="Discount percent"
                  value={productDiscount}
                  onChange={(event) => {
                    const newValue = event.target.value.trim();
                    // Check if the input is empty and handle backspace/delete key
                    if (newValue === "" || newValue === ".") {
                      setProductDiscount("");
                    } else if (
                      /^[0-9]+(\.[0-9]*)?$/.test(newValue) &&
                      parseFloat(newValue) >= 0 &&
                      parseFloat(newValue) <= 100
                    ) {
                      setProductDiscount(newValue);
                    } else {
                      setProductDiscount(productDiscount);
                    }
                  }}
                  step="0.01"
                  minLength={1}
                  maxLength={5}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid product discount.
                </Form.Control.Feedback>
                <CustomLabel style={{ fontSize: "12px", color: netural400 }}>
                  Determine discount for recurring orders. You can set 0% for no
                  promotions.
                </CustomLabel>
              </Form.Group>
            </Col>
            <Col md="12" lg="12" style={{ marginTop: "4%" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CustomLabel
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: neturalBlack,
                  }}
                >
                  Frequency
                </CustomLabel>
                <CustomLabel style={{ fontSize: "14px", color: netural400 }}>
                  The interval options your customers can choose among order
                  deliveries. E.g.: Every 2 months, every 3 weeks.
                </CustomLabel>
                <div style={{ marginTop: "3%" }}>
                  {["Monthly", "Weekly"].map((label, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        border: "solid 1px #dadada",
                        borderRadius: "12px",
                        padding: "3%",
                        marginBottom: "5%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Form.Check
                          inline
                          name="group1"
                          type={"radio"}
                          id={index}
                          checked={label === sellingPlanFrequency}
                          onChange={() => setSellingPlanFrequency(label)}
                        />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                          onClick={() => {
                            setSellingPlanFrequency(label);
                            setMinimumBillingCycle(1);
                          }}
                        >
                          <CustomLabel
                            style={{ fontSize: "16px", color: neturalBlack }}
                          >
                            {label}
                          </CustomLabel>
                          <CustomLabel
                            style={{
                              fontSize: "14px",
                              color: netural400,
                              marginTop: "2%",
                            }}
                          >
                            {label === "Weekly"
                              ? "Customers can choose from 1-8 weeks. After 8 week period, the options are: every 12,16,20 up to 24 weeks."
                              : "Customers can choose recurring orders from every 1 month up to 6 months"}
                          </CustomLabel>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col
              md="12"
              lg="12"
              style={{ marginTop: "4%", marginBottom: "4%" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CustomLabel
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: neturalBlack,
                  }}
                >
                  Billing cycle restrictions
                </CustomLabel>
                <div style={{ marginTop: "3%" }}>
                  <Form.Group style={{ color: "#A6A6A6" }}>
                    <Form.Label>Minimum billing cycles</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      size="sm"
                      style={{
                        borderRadius: "12px",
                        border: "1px solid #dadada",
                        fontSize: "1rem",
                      }}
                      value={minimumBillingCycle}
                      onChange={(value) => {
                        let selectedDropdownValue = value.target.value;
                        setMinimumBillingCycle(selectedDropdownValue);
                      }}
                    >
                      {billingDropDown.map((number, index) => {
                        return (
                          <option key={index} value={number}>
                            {number}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <CustomLabel
                      style={{ fontSize: "12px", color: netural400 }}
                    >
                      The minimum recurring orders before your customer can
                      cancel the subscription. The default value is one.
                    </CustomLabel>
                  </Form.Group>
                </div>
              </div>
            </Col>
            <FormFooterButtons
              displayPreviousButton={false}
              nextButtonText={"Continue"}
            />
          </Col>
          <Col xl={6} lg={6} md={6}>
            <Image
              src="../images/subscription-image.png"
              alt="subscription-image"
              style={{ width: "90%" }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SubscriptionGeneralInfoForm;
