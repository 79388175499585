import { Col, Row } from "react-bootstrap";
import {
  netural400,
  neturalBlack,
  primaryOrange,
  statusCancelled,
  success50,
} from "../../constants/color";
import { Link } from "react-router-dom";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import DropDwonWithPaginationHeader from "../drop-down-with-pagination-header/drop-down-with-pagination-header.component";
import EmptySubscriptionSellingPlan from "../empty-subscription-selling-plan/empty-subscription-selling-plan.component";
import SubscriptionListHeader from "../subscription-list-header/subscription-list-header.component";

const SellingPlanListCard = ({
  totalCount,
  sellingPlanList,
  page,
  type,
  setType,
  setPage,
  totalPages,
  pageSize,
  loading,
  search,
  dropDownValue,
  headerValue,
}) => {
  // Calculate SR_NO for each record
  const calculateSR_NO = (recordIndex) => {
    return (page - 1) * pageSize + recordIndex + 1;
  };

  return (
    <div
      style={{
        marginTop: "3%",
        backgroundColor: "white",
        width: "100%",
        borderRadius: "12px",
        paddingBottom: "1%",
      }}
    >
      <DropDwonWithPaginationHeader
        type={type}
        setType={setType}
        loading={loading}
        dropDownValue={dropDownValue}
        totalCount={totalCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        totalPages={totalPages}
      />
      {search.trim().length == 0 && totalCount === 0 ? null : (
        <SubscriptionListHeader headerValue={headerValue} type={type} />
      )}
      {loading ? (
        <div style={{ marginTop: "4%" }}>
          <LoadingSpinner overlay={false} />
        </div>
      ) : search.trim().length === 0 && totalCount === 0 ? (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
          <EmptySubscriptionSellingPlan
            mainTitle="No selling plans added yet"
            subTitle="Start now by creating a selling plan to enable recurring orders for your products."
            handleButtonClick="create"
          />
        </div>
      ) : search.trim().length != 0 && totalCount === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "3%",
          }}
        >
          <label
            style={{ fontSize: "18px", fontWeight: "600", color: neturalBlack }}
          >
            No {type} found
          </label>
        </div>
      ) : (
        sellingPlanList.map((data, index) => {
          return (
            <div
              style={{ marginTop: "2%", marginLeft: "1%", marginBottom: "2%" }}
              key={index}
            >
              {type === "Selling plans" ? (
                <Row>
                  <Col xl={3} lg={3} md={3}>
                    <label style={{ fontSize: "16px" }}>
                      {calculateSR_NO(index)}
                    </label>
                  </Col>
                  <Col xl={3} lg={3} md={3}>
                    <label style={{ fontSize: "16px" }}>{data.planName}</label>
                  </Col>
                  <Col xl={3} lg={3} md={3}>
                    <label style={{ fontSize: "16px" }}>
                      {data.discount} %
                    </label>
                  </Col>
                  <Col xl={3} lg={3} md={3}>
                    <Link
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: primaryOrange,
                        textDecoration: "none",
                      }}
                      to={`sellingplandetails/${btoa(data._id)}`}
                    >
                      View
                    </Link>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xl={2} lg={2} md={2}>
                    <label style={{ fontSize: "16px" }}>
                      {calculateSR_NO(index)}
                    </label>
                  </Col>
                  <Col
                    xl={2}
                    lg={2}
                    md={2}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.productTitle}
                    </label>
                    {data.variantCombination && (
                      <label style={{ fontSize: "14px", color: netural400 }}>
                        {data.variantCombination}
                      </label>
                    )}
                  </Col>
                  <Col xl={2} lg={2} md={2}>
                    <label
                      style={{
                        color: data.status === "active" ? "#024600" : "#620707",
                        fontSize: "16px",
                        backgroundColor:
                          data.status === "active"
                            ? success50
                            : statusCancelled,
                        borderRadius: "12px",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        textTransform: "capitalize",
                      }}
                    >
                      {data.status}
                    </label>
                  </Col>
                  <Col xl={2} lg={2} md={2}>
                    <label
                      style={{
                        fontSize: "16px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data.fullName}
                    </label>
                  </Col>

                  <Col xl={2} lg={2} md={2}>
                    <label style={{ fontSize: "16px" }}>{data.createdAt}</label>
                  </Col>
                  <Col xl={2} lg={2} md={2}>
                    <Link
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: primaryOrange,
                        textDecoration: "none",
                      }}
                      to={`details/${btoa(data._id)}`}
                    >
                      View
                    </Link>
                  </Col>
                </Row>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default SellingPlanListCard;
