import { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { netural400 } from "../../constants/color";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const DropDwonWithPaginationHeader = (props) => {
  const {
    type,
    setType,
    loading,
    dropDownValue,
    totalCount,
    page,
    pageSize,
    setPage,
    totalPages,
  } = props;
  return (
    <div
      style={{
        paddingTop: "1%",
        paddingBottom: "1%",
        display: "flex",
        borderBottom: "1px solid #dadada",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Col xl={3} lg={3} md={3}>
          <Form.Group
            controlId="validationCustom01"
            style={{ color: "#A6A6A6", width: "70%" }}
          >
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "12px",
                border: "0px solid rgb(0, 0, 0, 0.1)",
                fontWeight: "bold",
              }}
              onChange={(value) => {
                setType(value.target.value);
              }}
              // disabled={type === "Selling plans" ? true : loading}
              value={type}
            >
              {dropDownValue?.map((title, index) => {
                return (
                  <option key={index} value={title}>
                    {title}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={7} sm={7} xs={7}></Col>
        {!loading && totalCount !== 0 ? (
          <Fragment>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <label
                  style={{
                    fontSize: "14px",
                    color: netural400,
                    marginRight: "2%",
                  }}
                >
                  {`${(page - 1) * pageSize + 1} - ${Math.min(
                    page * pageSize,
                    totalCount
                  )}`}
                </label>
                <label
                  style={{
                    fontSize: "14px",
                    color: netural400,
                    marginRight: "2%",
                  }}
                >
                  of
                </label>
                <label style={{ fontSize: "14px", color: netural400 }}>
                  {totalCount}
                </label>
              </div>
            </Col>
            <Col
              lg={1}
              md={1}
              sm={1}
              xs={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: "2%" }}>
                <BsChevronLeft
                  color={netural400}
                  onClick={() => {
                    if (page !== 1) {
                      setPage(page - 1);
                    }
                  }}
                />
              </div>
              <div>
                <BsChevronRight
                  color={netural400}
                  onClick={() => {
                    if (page !== totalPages) {
                      setPage(page + 1);
                    }
                  }}
                />
              </div>
            </Col>
          </Fragment>
        ) : null}
      </Row>
    </div>
  );
};

export default DropDwonWithPaginationHeader;
