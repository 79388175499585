import { Fragment } from "react";
import { netural400, neturalBlack } from "../../constants/color";

const CustomerCard = ({ userInfo }) => {
  return (
    <div
      style={{
        marginTop: "5%",
        paddingBottom: "4%",
        borderBottom: "1px solid #dadada",
      }}
    >
      <Fragment>
        <label
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: neturalBlack,
          }}
        >
          Customer
        </label>
      </Fragment>
      <div style={{ marginTop: "3%" }}>
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          {userInfo.userName ? userInfo.userName : userInfo.fullName}
        </label>
      </div>
      <div style={{ marginTop: "1%" }}>
        <label style={{ fontSize: "18px", color: netural400 }}>
          {userInfo.contactNumber
            ? userInfo.contactNumber
            : userInfo.phoneNumber}
        </label>
      </div>
      <div style={{ marginTop: "1%" }}>
        <label style={{ fontSize: "16px", color: netural400 }}>
          {userInfo.email}
        </label>
      </div>
    </div>
  );
};

export default CustomerCard;
