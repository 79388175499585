import { Col, Image, Row } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400, neturalBlack } from "../../constants/color";
import CustomLinkButtonWithIcon from "../link-button-with-icon/link-button-with-icon.component";

const EmptySubscriptionSellingPlan = (props) => {
  const { mainTitle, subTitle, showButton, buttonText, handleButtonClick } =
    props;
  return (
    <div
      style={{
        // height: "30vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Image
        src="../images/subscription-image.png"
        alt="subscription-image"
        style={{ width: "30%" }}
      />
      <CustomLabel
        style={{
          paddingLeft: "2%",
          fontSize: "20px",
          fontWeight: "bold",
          color: neturalBlack,
          marginTop: "2%",
        }}
      >
        {mainTitle}
      </CustomLabel>
      <CustomLabel
        style={{
          paddingLeft: "2%",
          fontSize: "14px",
          color: netural400,
        }}
      >
        {subTitle}
      </CustomLabel>
      {showButton && (
        <div style={{ width: '20%', marginTop: '3%' }} >
          <CustomLinkButtonWithIcon
            title={buttonText}
            linkToNavigate={handleButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default EmptySubscriptionSellingPlan;
